import React from 'react';

import './SectionCatalogue.scss';

export default function SectionCatalogue() {
  return (
    <div className="section-catalogue">
      <div className="container-2-columns">
        <div className="container-img">
          <img src="/Catalogo2024.webp" alt="Catalogo"/>
        </div>
        <div>
          <div className="container-info-center">
            <div>
              <h2>
                ¡Estrenamos catálogo!
              </h2>
              <div>
                <p>
                Estamos muy contentos de presentarte nuestro nuevo catálogo 2024-25, donde están integrados los últimos lanzamientos de la marca como Ergohuman, Enjoy, Root, Esencia con mamparas y todos los productos de Motion Collection, entre otros.
                </p>
                <p>
                También incluye las nuevas líneas de tapices: Element, Expo y Target.
                </p>
                <p>
                ¡Además todos los productos ya cuentan con nombre!
                </p>
              </div>
              <div className="mt-10">
                <a target="_blank" href="/files/Catalogo.pdf" className="btn">
                VER NUEVO CATÁLOGO
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}